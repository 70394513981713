import * as React from 'react'
import { BaseLayout } from './base'
import { classNames } from 'lib/shared-ui'

interface Props {
    bg?: string
    smFullscreen?: boolean
}

export const AppLayout: React.FC<Props> = ({ children, bg, smFullscreen }) => {
    return (
        <BaseLayout>
            <div
                className={classNames(
                    'flex-1 relative z-0 flex overflow-hidden min-h-screen',
                    bg || 'bg-white'
                )}
            >
                <main
                    className={classNames(
                        smFullscreen ? '' : 'px-4 sm:px-6 pt-5',
                        'flex-1 overflow-y-auto focus:outline-none mx-auto max-w-6xl md:px-2 md:pt-8 pb-28'
                    )}
                >
                    {children}
                </main>
            </div>
        </BaseLayout>
    )
}
