import * as React from 'react'
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline'
import { Drawer, Header, Logo } from 'lib/shared-ui'
import Link from 'next/link'
import { Navigation, NotificationsMenuItem } from './navigation/navigation'
import { useUser } from '../auth'

export const BaseLayout: React.FC = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = React.useState(false)
    const { isLoading, role } = useUser()

    return (
        <div>
            <Drawer show={sidebarOpen} onClose={() => setSidebarOpen(false)}>
                <div className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-5 pt-3 flex justify-between">
                            <Header level="4">Menu</Header>
                            <XIcon
                                className="h-6 w-6 text-black"
                                aria-hidden="true"
                                onClick={() => setSidebarOpen(false)}
                            />
                        </div>
                        <Navigation />
                    </div>
                </div>
            </Drawer>

            {/* Static sidebar for desktop */}
            <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow bg-white pt-5 overflow-y-auto border-r border-gray-100">
                    <div className="flex items-center flex-shrink-0 px-6">
                        <Link href="/" passHref>
                            <a className="no-underline text-current inline-flex items-center">
                                <Logo />
                            </a>
                        </Link>
                    </div>
                    <div className="mt-2 flex-grow flex flex-col">
                        <Navigation />
                    </div>
                </div>
            </div>
            <div className="md:pl-64 flex flex-col flex-1 ">
                <div className="flex-shrink-0 flex justify-between h-14 shadow md:hidden border-b border-gray-200">
                    <div className="flex items-center ml-4">
                        <Link href="/" passHref>
                            <a>
                                <Logo />
                            </a>
                        </Link>
                    </div>
                    <span className="flex items-center">
                        {role === 'customer' && !isLoading ? (
                            <NotificationsMenuItem />
                        ) : null}
                        <button
                            type="button"
                            className="px-5 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>
                    </span>
                </div>

                {children}
            </div>
        </div>
    )
}
